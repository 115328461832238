import { render, staticRenderFns } from "./uploadFile.vue?vue&type=template&id=510919bb&scoped=true"
import script from "./uploadFile.vue?vue&type=script&lang=js"
export * from "./uploadFile.vue?vue&type=script&lang=js"
import style0 from "./uploadFile.vue?vue&type=style&index=0&id=510919bb&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "510919bb",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/mer_plat_admin_khon/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('510919bb')) {
      api.createRecord('510919bb', component.options)
    } else {
      api.reload('510919bb', component.options)
    }
    module.hot.accept("./uploadFile.vue?vue&type=template&id=510919bb&scoped=true", function () {
      api.rerender('510919bb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/base/uploadFile.vue"
export default component.exports